import React from 'react';
import {Classes, Dialog, DialogProps, FormGroup, Spinner} from '@blueprintjs/core';
import classNames from 'classnames';
import {DateTime} from 'luxon';
import {formatPhoneNumber} from 'react-phone-number-input';
import {OrderStatusBadge} from '../../components/ui/OrderStatusBadge';
import {RatingStars} from '../../components/ui/rating.component';
import {OrderItemRow} from './order-item-row.component';
import {getCustomerName, sortOrderItems} from '../../utils/helpers.utils';
import {Timezone} from '../../utils/types';

import {useAuth} from '../../contexts/auth.context';
import {useQuery} from 'react-query';
import {fetchOrderRequest} from '../../api/order.api';
import {PaymentMethodItem} from './PaymentMethodItem';
import {Link, useParams} from 'react-router-dom';

interface OrderDetailsDialogProps extends DialogProps {
  orderId: number;
  timezone: Timezone;
}

export const OrderDetailsDialog: React.FC<OrderDetailsDialogProps> = ({orderId, timezone, ...props}) => {
  const {user} = useAuth();
  const params = useParams<{storeId: string}>();

  const orderQuery = useQuery({
    queryKey: ['order', orderId],
    queryFn: () => fetchOrderRequest(user.token, orderId),
  });

  const order = orderQuery.data;

  const time = order ? DateTime.fromISO(order.createdAt).setZone(timezone).toFormat('d/MM/yyyy, h:mm:ss a ZZZZ') : '';

  return (
    <Dialog
      title={
        order && (
          <div className="tw-flex tw-justify-between">
            <div className="dialog-title">
              <label>Order #{order?.number}</label>
              <p>{time}</p>
            </div>

            <div className="tw-p-1 tw-flex tw-items-center">
              {order.status === 'delivered' && (
                <Link
                  to={`../../orders/${order.id}/receipt`}
                  target="_blank"
                  className="tw-bg-blue-500 tw-text-white hover:tw-text-white hover:tw-no-underline tw-p-1 tw-px-2 tw-rounded tw-text-sm"
                >
                  View Receipt
                </Link>
              )}
            </div>
          </div>
        )
      }
      className="kl-dialog order-details-dialog"
      {...props}
    >
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        {!order ? (
          <div className="tw-p-10">
            <Spinner />
          </div>
        ) : (
          <>
            <h4 className="section-title">Order details</h4>
            <div className="location-section">
              <div className="left-content">
                <FormGroup label={order.eventId ? 'Event' : 'Ordering method'} className="name">
                  {order.event?.name ?? order.location?.section?.name ?? order.locationGroups?.section?.name}
                </FormGroup>

                <FormGroup label="Customer">
                  {getCustomerName(order?.customer) ?? 'No name'}
                  <br />
                  {!!order.customer?.phone && formatPhoneNumber(order.customer.phone)}
                </FormGroup>

                {order.orderAttributeValues?.map(({orderAttribute, value}) => (
                  <FormGroup key={orderAttribute?.id} label={orderAttribute?.name}>
                    {value}
                  </FormGroup>
                ))}
              </div>
              <div className="right-content">
                <FormGroup label="Status">
                  {order.status && (
                    <>
                      <OrderStatusBadge status={order.status} />
                      <FormGroup className="status-updated-at" label="Status Updated At">
                        {DateTime.fromISO(order.statusUpdatedAt).toFormat('MM/dd/yy h:mm a')}
                      </FormGroup>
                    </>
                  )}
                </FormGroup>
                {!!order.scheduleDate && (
                  <FormGroup label="Scheduled for">
                    {DateTime.fromISO(order.scheduleDate).setZone(timezone).toFormat('d/MM/yy, h:mm a ZZZZ')}
                  </FormGroup>
                )}
                {order?.customerNotes && (
                  <FormGroup label="Customer Notes">
                    <i>{order.customerNotes}</i>
                  </FormGroup>
                )}
                {order?.type === 'takeaway' && (
                  <>
                    <FormGroup label="Pickup info">
                      <p>{order.pickupName}</p>
                      <p className="pickup-info">{order.pickupInfo}</p>
                    </FormGroup>
                  </>
                )}
              </div>
            </div>

            <h4 className="section-title">Items & Cost</h4>
            <div className="order-items-list">
              {sortOrderItems(order?.items ?? [])?.map((item) => <OrderItemRow key={item.id} item={item} />) ?? null}
              <OrderItemRow item={{quantity: 1, type: 'total', title: 'Total', subtotal: order?.total ?? 0}} />
            </div>

            <h4 className="section-title">Payment Method</h4>
            <div className="payment-method-section">
              {order?.payment ? (
                <PaymentMethodItem
                  cardBrand={order.payment.cardBrand ?? ''}
                  lastFourDigits={order.payment.cardDigits ?? ''}
                  cardHolderName={order.payment?.cardName!}
                />
              ) : order?.roomNumber ? (
                <div className="room-charge">
                  <h4>Room Charge</h4>
                  <p>Room number: {order.roomNumber}</p>
                  <p>Last name: {order.roomGuestLastName}</p>
                </div>
              ) : (
                <h4>Manual Payment</h4>
              )}
            </div>

            <h4 className="section-title">Experience Rating</h4>
            <div className="rating-section">
              {order?.feedback ? (
                <>
                  <RatingStars starCount={5} rating={order?.feedback?.rating ?? 0} />
                  <span className="feedback-notes">{order?.feedback?.note}</span>
                </>
              ) : (
                <label>This order has not been rated yet</label>
              )}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
